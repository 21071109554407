// Version 1 - 20220615

import Splide from "./splide.min.js";
import { displayAllAccessories } from "./pageParts";
import { select } from "async";
import { customElement, settingAllAttributes } from "./modules.js";

let pageUrl = window.location.href,
    websiteAddress = window.location.origin;

// Loading Gallery
const loadGallery = () => {
    const galleryContainer = document.querySelector('.conform-formboards-category-gallery');

    if (galleryContainer != null) {
        const splideContainer = document.querySelector('.splide.formboards-gallery .splide__track .splide__list'),
            slide = document.querySelector('.splide.formboards-gallery .splide__track .splide__list .splide__slide');

        if (!galleryContainer.classList.contains('no-images')) {
            const splide = new Splide('.splide.formboards-gallery', {
                type: 'slide',
                gap: 15,
                autoWidth: true,
                focus: 'center',
                trimSpace: false,
                start: 0,
                rewind: true,
            });
            splide.mount();

            // Adjust Centered Focus for Starting Slide
            // const margin = (splideContainer.offsetWidth - slide.offsetWidth) / 2; // 1520 width of the splide | 1180 width of the slide
            // let splideContainerWidth = (slide.offsetWidth + 15) - margin; // 1180 width of the slide | 15 is the gap

            // splideContainer.style.transform = 'translateX(-' + splideContainerWidth + 'px)';
        }
    }
}

// Loading all Con-form boards family
const loadConformBoardsFamily = () => {
    const splideContainer = document.querySelector('.splide.conform-boards-range');

    if (splideContainer != null) {
        const splide = new Splide('.splide.conform-boards-range', {
            padding: '60px',
            gap: 15,
            rewind: true,
            perPage: 5,

            breakpoints: {
                1521: { perPage: 4 },
                1261: { perPage: 3 },
                961: { perPage: 2 },
                671: { perPage: 1, autoWidth: true, focus: 'center', trimSpace: false }
            }
        });
        splide.mount();
    }
}

// Loading other Accessories
const displayOtherAccessories = () => {
    const splideContainer = document.querySelector('.splide.conform-other-accessories__products');

    if (splideContainer != null) {
        const splide = new Splide('.splide.conform-other-accessories__products', {
            padding: '60px',
            gap: 15,
            rewind: true,
            perPage: 5,
            breakpoints: {
                1521: { perPage: 4 },
                1261: { perPage: 3 },
                961: { perPage: 2 },
                671: { perPage: 1, autoWidth: true, focus: 'center', trimSpace: false }
            }
        });
        splide.mount();
    }
}

// Formboard Range Section
export const displayOtherFormboardsPhotos = () => {
    const formboards = document.querySelectorAll('.conform-other-categories__category');
    let windowSize = window.innerWidth,
        reso = '';

    if (windowSize > 991) {
        reso = '-default';
    } else if (windowSize > 767 && windowSize < 992) {
        reso = '-large';
    } else if (windowSize > 549 && windowSize < 768) {
        reso = '-medium';
    } else {
        reso = '-small';
    }

    let mediaURL = websiteAddress.includes('localhost') ? websiteAddress + '/conform' : websiteAddress;

    formboards.forEach(function (e) {
        // let assetURL = websiteAddress + websiteAddress.includes('localhost') ? '/conform' : '';
        // let imgURL = websiteAddress + assetURL + '/wp-content/uploads/' + e.getAttribute('data-img') + reso + '.jpg';
        let imgURL = mediaURL + '/wp-content/uploads/' + e.getAttribute('data-img') + reso + '.jpg';
        e.style.backgroundImage = "url('" + imgURL + "')";
    });
}

// For formboard category preview slider
export const productPreview = (e) => {
    // let assetURL = websiteAddress + websiteAddress.includes('localhost') ? '/conform' : '';
    let mediaURL = websiteAddress.includes('localhost') ? websiteAddress + '/conform' : websiteAddress;

    if (e != null) {
        const sizeOptions = document.querySelectorAll('.conform-regular-link-buttons--product-dimensions');

        e.classList.add('active');
        e.disabled = true;

        document.querySelector('.conform-product-category-specs__product-desc').innerHTML = e.getAttribute('data-product-name');
        // document.querySelector('.conform-product-category-specs__product-code').innerHTML = '<span>Product Code: </span><br>' + e.getAttribute('data-product-code');
        document.querySelector('.conform-product-category-specs__product-code').innerHTML = e.getAttribute('data-product-code');

        // document.querySelector('.conform-product-category-image-full').setAttribute('srcset', websiteAddress + assetURL + '/wp-content/uploads/' + e.getAttribute('data-full-image'));

        document.querySelector('.conform-product-category-specs__image-here').setAttribute('src', e.getAttribute('data-full-image'));

        // if (e.getAttribute('data-product').includes('formboard')) {
        //     document.querySelector('.conform-product-category-specs__formboards-image-here').setAttribute('src', e.getAttribute('data-full-image'));
        // } else {
        //     document.querySelector('.conform-product-category-specs__image-here').setAttribute('src', e.getAttribute('data-full-image'));
        // document.querySelector('.conform-product-category-image-full').setAttribute('srcset', mediaURL + '/wp-content/uploads/' + e.getAttribute('data-full-image'));
        // document.querySelector('.conform-product-category-image-medium').setAttribute('srcset', mediaURL + '/wp-content/uploads/' + e.getAttribute('data-image-medium'));
        // document.querySelector('.conform-product-category-image-small').setAttribute('srcset', mediaURL + '/wp-content/uploads/' + e.getAttribute('data-image-small'));
        // document.querySelector('.conform-product-category-image-default').setAttribute('src', mediaURL + e.getAttribute('data-full-image'));
        // }

        sizeOptions.forEach(function (option) {
            if (option.getAttribute('data-product') != e.getAttribute('data-product') && option.classList.contains('active')) {
                option.classList.remove('active');
                option.disabled = false;
            }
        });
    }
}

// MAIN CONTENT DISPLAY

export const categoryPageContent = () => {
    productPreview(document.querySelector('.conform-regular-link-buttons--product-dimensions.active'));
    loadGallery();
    loadConformBoardsFamily();
    displayOtherFormboardsPhotos();
    displayOtherAccessories();
}