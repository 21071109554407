/**
 * Global Functions JS
 * Author: WebGuy 2022
 * Version: 2022.02.01
 */

import Splide from "./splide.min.js";

// WINDOWS RELOAD
export function ready(callBackFunc) {
    if (document.readyState !== 'loading') {
        callBackFunc();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', callBackFunc);
    } else {
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callBackFunc();
            }
        });
    }
}

// CREATES A CUSTOM ELEMENT
export const customElement = function (element, clas, val = null, withHTML = false) {
    let el = document.createElement(element);

    el.setAttribute('class', clas);
    if (val)
        (withHTML) ? el.innerText = val : el.innerHTML = val;

    return el;
}

// CREATES EVENT LISTENERS
export const addGlobalEventListeners = function (type, selectors, callback) {
    for (let s = 0; s < selectors.length; s++) {
        document.addEventListener(type, e => {
            if (e.target.matches(selectors[s])) callback(e)
        });
    }
}

// Setting All Attrib
export const settingAllAttributes = (el, attribs) => {
    console.log(attribs);
    for (let a = 0; a < attribs.length; a++) {
        console.log(attribs[a][0], attribs[a][1]);
        console.log(el);
        // el.setAttribute(attribs[a][0], attribs[a][1]);
    }
}

// Mounting the Splide

export const mountTheSplide = (spliteInit, type) => {
    switch (type) {
        case 'single-gallery':
            var args = {
                type: 'slide',
                autoplay: true,
                gap: 20,
                autoHeight: true,
                focus: 'center',
                trimSpace: 'move',
                start: 0,
                rewind: true
            }
            break;
        default:
            break;
    }

    const splideContainer = new Splide(spliteInit, args);
    splideContainer.mount();
}