// Version 1 - 20220601

import Splide from "./splide.min.js";
import { displayAllAccessories } from "./pageParts";
import { mountTheSplide, whatServerAssets } from "./modules";

let pageUrl = window.location.href,
    websiteAddress = window.location.origin;

// Plastic Formboard Range Section
export const displayFormboardPhotos = () => {
    const formboards = document.querySelectorAll('.conform-main-product-range__category');
    let windowSize = window.innerWidth,
        reso = '';

    if (windowSize > 991) {
        reso = '-default';
    } else if (windowSize > 767 && windowSize < 992) {
        reso = '-large';
    } else if (windowSize > 549 && windowSize < 768) {
        reso = '-medium';
    } else {
        reso = '-small';
    }

    // let assetURL = websiteAddress + websiteAddress.includes('localhost') ? '/conform' : '';

    // console.log(websiteAddress.includes('localhost'));
    // console.log('hello ' + websiteAddress);
    // console.log(assetURL);

    let mediaURL = websiteAddress.includes('localhost') ? websiteAddress + '/conform' : websiteAddress;

    formboards.forEach(function (e) {
        // let imgURL = websiteAddress + assetURL + '/wp-content/uploads/' + e.getAttribute('data-img') + reso + '.jpg';
        let imgURL = mediaURL + '/wp-content/uploads/' + e.getAttribute('data-img') + reso + '.jpg';
        e.style.backgroundImage = "url('" + imgURL + "')";
    });
}

// HOME BANNER SLIDER
const initiateHomeBannerSlider = () => {
    mountTheSplide('.splide.home-banner-slider', 'single-gallery');
}

// MAIN CONTENT DISPLAY

export const homePageContent = () => {
    displayFormboardPhotos();

    if (document.querySelector('.conform-home-banner-slider') !== null) {
        initiateHomeBannerSlider();
    }
}