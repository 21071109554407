/**
 * CON-FORM CORE JS
 * Author: WebGuy 2022
 * Version: 2022.02.01
 */

import "./style.scss";

import { homePageContent, displayFormboardPhotos } from "./app/homePageContent.js";
import { categoryPageContent, displayOtherFormboardsPhotos, productPreview } from "./app/categoryPage.js";
import { ready, addGlobalEventListeners } from "./app/modules";
// import { formatWarrantyPage } from "./app/warranty";

let pageUrl = window.location.href,
    websiteAddress = window.location.origin;

// =========================================================================================
// NOTIFICATIONS - NOTIFICATION BAR
// =========================================================================================

// SET LOCALSTORAGE FOR NOTIFICATIONS
const setNotificationsDisplay = () => {
    if (sessionStorage.getItem('conform-notifications') == null) {
        sessionStorage.setItem('conform-notifications', 'set');

        document.querySelectorAll('.conform-top-notification').forEach(notif => {
            sessionStorage.setItem(notif.getAttribute('data-notif'), 'display');
        });
    }
}

// CHECK NOTIFICATIONS STATUS
const checkNotifications = () => {
    const notifs = document.querySelectorAll('.conform-top-notification');

    notifs.forEach(notif => {
        if (sessionStorage.getItem(notif.getAttribute('data-notif')) == 'display') {
            if (notif.classList.contains('hide')) {
                notif.classList.remove('hide');
            }
        } else {
            if (!notif.classList.contains('hide')) {
                notif.classList.add('hide');
            }
        }
    });
}

// NOTIFICATIONS CLICK EVENTS
const notifEvents = (data, type = 'link') => {
    const notifs = document.querySelectorAll('.conform-top-notification');

    notifs.forEach(notif => {
        if (notif.getAttribute('data-notif') == data.target.getAttribute('data-notif')) {
            notif.classList.add('hide');
        }
    });
    sessionStorage.setItem(data.target.getAttribute('data-notif'), 'hide');

    if (type == 'link') {
        // window.location.replace(data.target.getAttribute('data-notif-link'));
        window.open(data.target.getAttribute('data-notif-link'), '_blank');
    }
}

// =========================================================================================
// WINDOWS RELOAD CONTENT
// =========================================================================================

const siteURL = urlLink.siteUrl,
    tLink = templateLink.templateLink;

ready(function () {

    // NOTIFICATIONS FOR NOTIFICATION BAR
    setNotificationsDisplay();
    checkNotifications();

    homePageContent();
    categoryPageContent();
    // formatWarrantyPage();

});

// =========================================================================================
// RESIZE WINDOW TRIGGER
// =========================================================================================

window.addEventListener('resize', function () {
    // ! Update Formboards Images Resolution - Home Page
    displayFormboardPhotos();
    displayOtherFormboardsPhotos();
});

// =========================================================================================
// CORE EVENTS
// =========================================================================================

// Hide Notification by Closing
addGlobalEventListeners('click', ['.conform-regular-link-buttons--close-notif'], e => {
    notifEvents(e, 'close');
});

addGlobalEventListeners('click', ['.conform-regular-link-buttons--notif-link'], e => {
    notifEvents(e);
});

// Online Store Modal
addGlobalEventListeners('click', ['.conform-large-link-buttons--white.buy-now'], e => {
    const onlineStoreContainer = document.querySelector('.conform-online-stores');

    if (onlineStoreContainer.classList.contains('hide')) {
        onlineStoreContainer.classList.remove('hide');
    } else {
        onlineStoreContainer.classList.add('hide');
    }
});

addGlobalEventListeners('click', ['.conform-online-stores__close-button'], e => {
    const onlineStoreContainer = document.querySelector('.conform-online-stores');

    if (!onlineStoreContainer.classList.contains('hide')) {
        onlineStoreContainer.classList.add('hide');
    }
});

// Product Dropdown Links
addGlobalEventListeners('click', ['.conform-main-menu__link-item.dropdown'], e => {
    const dropdownContainer = document.querySelector('.conform-main-header__dropdown-menu'),
        arrow = document.querySelector('.conform-main-menu__link-item.dropdown .fa-solid.fa-caret-down');

    if (dropdownContainer.classList.contains('disabled')) {
        e.target.classList.add('active');
        arrow.style.transform = 'rotate(180deg)';

        dropdownContainer.classList.remove('disabled');
    } else {
        e.target.classList.remove('active');
        arrow.style.transform = 'rotate(360deg)';

        dropdownContainer.classList.add('disabled');
    }
});

document.addEventListener('click', e => {
    const dropdownContainer = document.querySelector('.conform-main-header__dropdown-menu'),
        dropdownNav = document.querySelector('.conform-dropdown-menu'),
        dropdownButton = document.querySelector('.conform-main-menu__link-item.dropdown'),
        arrow = document.querySelector('.conform-main-menu__link-item.dropdown .fa-solid.fa-caret-down');

    if (!e.target.closest('.conform-main-menu__link-item.dropdown') && e.target !== dropdownContainer && e.target !== dropdownNav) {
        if (!dropdownContainer.classList.contains('disabled')) {
            dropdownButton.classList.remove('active');
            arrow.style.transform = 'rotate(360deg)';

            dropdownContainer.classList.add('disabled');
        }
    }
});

// Dropdown Hamburger Menu
document.querySelector('.conform-button-icons--hamb-menu').addEventListener('click', e => {
    const mobileDropdownMenu = document.querySelector('.conform-main-header__mobile-dropdown-menu');

    if (!e.target.classList.contains('active')) {
        e.target.classList.add('active');
        mobileDropdownMenu.classList.remove('disabled');
    } else {
        e.target.classList.remove('active');
        mobileDropdownMenu.classList.add('disabled');
    }
});

// Sticky Navigation 
window.addEventListener('scroll', function () {
    const header = document.querySelector('.conform-main-header');
    let windowSize = window.innerWidth;

    if (windowSize > 849) {
        header.classList.toggle("sticky", window.scrollY > 0);
    }
});

// For product category sizes
addGlobalEventListeners('click', ['.conform-regular-link-buttons--product-dimensions'], e => {
    productPreview(e.target);
});

// For FAQS
addGlobalEventListeners('click', ['.conform-faqs-list__questions-container'], e => {
    let parent = e.target.parentNode,
        parentID = parent.getAttribute('data-id');

    // Activate Faq item
    if (parent.classList.contains('active')) {
        closeAllActiveFaqs('all');
    } else {
        parent.classList.add('active');
        closeAllActiveFaqs(parentID);
    }
});

const closeAllActiveFaqs = (id) => {
    const faqs = document.querySelectorAll('.conform-faqs-list__item');

    faqs.forEach(e => {
        if (id == 'all') {
            if (e.classList.contains('active')) {
                e.classList.remove('active');
            }
        } else {
            if (e.getAttribute('data-id') != id) {
                if (e.classList.contains('active')) {
                    e.classList.remove('active');
                }
            }
        }
    });
}

// Product Page Where To Buy Popup

addGlobalEventListeners('click', ['.conform-large-link-buttons--red.wtb.buy-now'], e => {
    document.querySelector('.conform-body').style.overflowY = 'hidden';
    document.querySelector('.conform-stores').classList.remove('hide');

    if (e.target.getAttribute('data-starter-kit') !== '' && e.target.getAttribute('data-starter-kit') !== null) {
        const starterKitsOnlineStores = document.querySelectorAll('.conform-list-online-stores.starter-kits');

        document.querySelector('.conform-stores__product-name.starter-kit').innerHTML = '<span>buy con-form</span><br>' + e.target.getAttribute('data-starter-kit-name');

        document.querySelector('.conform-stores__product-image.starter-kit').setAttribute('alt', 'buy con-form ' + e.target.getAttribute('data-starter-kit-name'));
        document.querySelector('.conform-stores__product-image.starter-kit').setAttribute('src', e.target.getAttribute('data-starter-kit-image'));

        starterKitsOnlineStores.forEach(starterKit => {
            if (e.target.getAttribute('data-starter-kit') == starterKit.getAttribute('data-starter-kit')) {
                starterKit.classList.remove('hide');
            } else {
                starterKit.classList.add('hide');
            }
        });
    }
});

addGlobalEventListeners('click', ['.conform-stores', '.conform-stores__close-button'], e => {
    document.querySelector('.conform-body').style.overflowY = 'visible';
    document.querySelector('.conform-stores').classList.add('hide');
});

addGlobalEventListeners('click', ['.conform-stores-tabs'], e => {
    if (!e.target.classList.contains('active')) {

        const tabButtons = document.querySelectorAll('.conform-stores-tabs');
        let dataContent = e.target.getAttribute('data-content-id');

        e.target.classList.add('active');

        if (dataContent == 'instore') {
            document.getElementById('conform-instores-list').classList.add('active');
            document.getElementById('conform-online-stores-list').classList.remove('active');

            document.querySelector
        } else {
            document.getElementById('conform-instores-list').classList.remove('active');
            document.getElementById('conform-online-stores-list').classList.add('active');
        }

        tabButtons.forEach(tabButton => {
            if (tabButton.getAttribute('data-content-id') != dataContent) {
                tabButton.classList.remove('active');
            }
        });
    }
});