// Version 2022.06.08

import Splide from "./splide.min.js";

let pageUrl = window.location.href;

// Display all accessories in a horizontal scroll

export const displayAllAccessories = () => {

    // 320 - 380 -- perPage 1
    // 380 - 650 -- perPage 2
    // 650 - 1080 -- perPage 3
    // 1080 -- perPage 4

    const splide = new Splide('.splide.conform-accessories__range', {
        padding: '0px',
        gap: 15,
        rewind: true,
        perPage: 4,
        breakpoints: {
            1079: { perPage: 3 },
            649: { perPage: 2 },
            413: { perPage: 1 }
        }
    });
    splide.mount();
}